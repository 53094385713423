import styled from "styled-components";

const TeamSection = styled.section`
  margin: 24px auto;
  width: 83.4%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
  column-gap: 48px;
  row-gap: 48px;

  @media screen and (max-width: 789px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(0, 1fr);
    margin: 15px auto;
    column-gap: 20px;
    row-gap: 20px;
  }
`;

const Heading = styled.div`
  text-align: center;
  font-size: 25px;
  color: #3c6255;
  font-bold: 20px;
  font-weight: bold;

  @media screen and (max-width: 789px) {
    font-size: 15px;
  }
`;

const Container = styled.div`
  margin: auto;
  max-width: 80%;
  @media screen and (max-width: 789px) {
    margin: auto;
  }
`;

export { TeamSection, Heading, Container };
