import janak from "assets/images/Home/pro-janak.png";
import shubham from "assets/images/Home/UI-shubham.png";
import shubhendu from "assets/images/Home/ML-shubhendu.png";
import parth from "assets/images/Home/U-parth.png";
import sameep from "assets/images/Home/sameep.png";

const data = [
  {
    id: 1,
    name: "Janak Patel",
    discripation: "Founder & Chief Data Analyst",
    pic: janak,
    link: "https://www.linkedin.com/in/janak-patel/",
  },

  {
    id: 2,
    name: " Shubham Patrick",
    discripation: "UI Software Engineer",
    pic: shubham,
    link: " https://www.linkedin.com/in/ahthneeuhl/",
  },

  {
    id: 3,
    name: "Shubhendu ghosh",
    discripation: "Junior AI-ML Software Engineer",
    pic: shubhendu,
    link: "https://www.linkedin.com/in/shubhendu-ghosh-423092205/",
  },

  {
    id: 4,
    name: "Parth Patel",
    discripation: "Junior UI Software Engineer",
    pic: parth,
    link: "https://www.linkedin.com/in/parth-patel-8a6478239",
  },

  {
    id: 5,
    name: "Sameep",
    discripation: "Product Marketing Associate",
    pic: sameep,
    link: "https://www.linkedin.com/in/sameep-hamilton-4512a31b6",
  },
];

export default data;
