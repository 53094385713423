import { WhatsappIcon } from "react-share";
import { Heading } from "./Common.styles";
import {
  ContactNumber,
  Description,
  SectionContainer,
} from "./ContactSection.styles";

const ContactSection = () => {
  return (
    <SectionContainer id="contact-us">
      <Heading>Contact Us</Heading>
      <Description>
        Don't miss out on this opportunity to take your business to the next
        level. Contact us today to schedule a tour and see our facilities for
        yourself.
      </Description>
      <ContactNumber>+91 7567557549</ContactNumber>
      <WhatsappIcon
        size={32}
        round={true}
        onClick={() => window.open("https://wa.me/+91932739548")}
        cursor="pointer"
        style={{
          width: "100%",
        }}
      />
    </SectionContainer>
  );
};

export default ContactSection;
