import styled from "styled-components";

const Container = styled.div`
  margin: 32px auto;
  width: 83.4%;
  margin-bottom: 8rem;

  @media screen and (max-width: 912px) {
    width: 88%;
    margin: 24px auto;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1.25rem;

  @media screen and (max-width: 912px) {
    flex-direction: column;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

const MainHeading = styled.h2`
  position: absolute;
  width: 100%;
  font-size: 30px;
  color: white;
  text-align: center;
  margin-top: 400px;

  @media screen and (max-width: 912px) {
    font-size: 15px;
    margin-top: 100px;
  }
`;

const Main = styled.h1`
  position: absolute;
  width: 100%;
  font-size: 50px;
  color: white;
  text-align: center;
  margin-top: 150px;
  color: red;

  @media screen and (max-width: 912px) {
    font-size: 20px;
    margin-top: 80px;
  }
`;

const Head = styled.h1`
  position: absolute;
  width: 100%;
  top: 10%;
  font-size: 50px;
  color: white;
  text-align: center;

  @media screen and (max-width: 912px) {
    font-size: 20px;
  }
`;

const Grid = styled.div`
  position: relative;
`;

const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
  .player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export {
  Container,
  ImageContainer,
  Image,
  Grid,
  MainHeading,
  PlayerWrapper,
  Head,
  Main,
};
