import styled from "styled-components";

const Container = styled.div`
  margin: 2rem auto;
  padding: 2rem;
  margin-bottom: 5rem;

  @media screen and (max-width: 912px) {
    padding: 1rem;
    margin: 1rem auto;
  }
`;

const Heading = styled.div`
  text-align: center;
  font-size: 25px;
  color: #3c6255;
  font-weight: bold;
  margin-bottom: 5rem;

  @media screen and (max-width: 912px) {
    font-size: 15px;
    margin-bottom: 4rem;
  }
`;

const ImageContainer = styled.div`
  gap: 2rem;
  display: flex;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
  box-shadow: 0 0 4px 0 #555555;

  @media screen and (max-width: 912px) {
    gap: 2rem;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
  }
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  gap: 3rem;
  margin-bottom: 3rem;

  @media screen and (max-width: 912px) {
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
`;

export { Container, Heading, ImageContainer, CardContainer };
