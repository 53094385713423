import {
  Container,
  TextContainer,
  FeaturesContainer,
  Feature,
  Logo,
  Features,
  Illustration,
  Actions,
} from "./DolphySection.styles";

import { Header, SubHeader, Description } from "components/core";

import { ReactComponent as LogoIcon } from "assets/icons/logo.svg";
import { Button } from "components/core";
import * as links from "config/Home/links";

const DolphySection = () => {
  return (
    <Container>
      <TextContainer>
        <Header>Dolphy</Header>
        <SubHeader>
          AI-Infused Digital Marketing <br />
          Intelligence Tool
        </SubHeader>
        <Description>
          Dolphy connects different digital touch points, processes data and
          helps digital marketers in building connected website-social media
          integrated approach to reach customers of interest through social
          media, divert them to your website or product page through
          personalized engagement powered by automation and AI. Dolphy one tool
          empowers marketers to unlock full potential of Twitter, Social Media
          Content, Website Analytics and Product keyword research.
        </Description>
      </TextContainer>
      <FeaturesContainer>
        <Features>
          <Feature>
            <Logo icon={LogoIcon} text="Web Analytics" variant="beta" />
          </Feature>
          <Feature>
            <Logo icon={LogoIcon} text="Easy Twitter" variant="beta" />
          </Feature>
          <Feature>
            <Logo
              icon={LogoIcon}
              text="Automate Social Media Post"
              variant="beta"
            />
          </Feature>
          <Feature>
            <Logo
              icon={LogoIcon}
              text="Product Search Demand & Forecast"
              variant="beta"
            />
          </Feature>
          <Feature>
            <Logo icon={LogoIcon} text="Recommendations" variant="beta" />
          </Feature>
        </Features>
        <Illustration />
      </FeaturesContainer>
      <Actions>
        <Button
          variant="beta"
          outline
          content="Learn More"
          onClick={() => (window.location.href = links.dolphySection.learnMore)}
        />
        <Button
          variant="beta"
          content="Sign up free"
          onClick={() =>
            (window.location.href = links.dolphySection.dolphyHome)
          }
        />
      </Actions>
    </Container>
  );
};

export default DolphySection;
