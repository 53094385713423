const {
  REACT_APP_DOLPHY_ROOT_URL: dolphyRoot,
  // REACT_APP_NULL_BACKEND_ROOT_URL: nullBackendRoot,
  REACT_APP_NULL_ROOT_URL: nullRoot,
} = process.env;

const navbar = {
  dolphy: dolphyRoot as string,
  tweefeed: `${dolphyRoot}/tweefeed`,
  contentfeed: `${dolphyRoot}/contentfeed`,
  dolphyAbout: `${dolphyRoot}/about`,
  // survivor: `${nullBackendRoot}/Survivr:A-Life-Saver-Band`,
  // aboutUs: `${nullBackendRoot}/about-us`,
  // blogs: `${nullBackendRoot}/blogs`,
};

const social = {
  facebook: "https://www.facebook.com/dolphy.io/",
  twitter: "https://twitter.com/dolphy_io/",
  linkedin: "https://www.linkedin.com/company/dolphyio/",
  youtube: "https://www.youtube.com/channel/UCye3MwiwYaTo8baXfwxZ5ng",
  instagram: "https://www.instagram.com/dolphy.io/",
  f6s: "https://www.f6s.com/nullllc1",
};

const footer = {
  // framegen: `${nullBackendRoot}/Framegen:You-Think-It-We'll-Build-It`,
  // career: `${nullBackendRoot}/Career-at-null-innovation`,
  home: nullRoot as string,
  // contactUs: `${nullBackendRoot}/contact-us`,
  // refundPolicy: `${nullBackendRoot}/refund-and-cancellation-policy`,
  // terms: `${nullBackendRoot}/terms-and-condition`,
  // dataPrivacy: `${nullBackendRoot}/Data-Privacy-Policy`,
  // whatWeDo: `${nullBackendRoot}/what-we-do`,
  doplhyContactUs: `${dolphyRoot}/contactUs`,
};

export { navbar, social, footer };
