import styled from "styled-components";

const Container = styled.div`
  margin: 32px auto;
  width: 83.4%;
  margin-bottom: 8rem;

  @media screen and (max-width: 912px) {
    width: 88%;
    margin: 24px auto;
  }
`;

const Heading = styled.div`
  text-align: center;
  font-size: 20px;
  color: #3c6255;
  font-weight: bold;

  @media screen and (max-width: 912px) {
    font-size: 20px;
  }
`;

const Header = styled.h5`
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 5rem;

  @media screen and (max-width: 912px) {
    margin-bottom: 2rem;
  }
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-bottom: 3rem;

  @media screen and (max-width: 912px) {
    gap: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

const CardItem = styled.div`
  max-width: 100%;

  @media screen and (max-width: 912px) {
    max-width: 80%;
  }
`;

const Company = styled.img`
  width: 150%;

  @media screen and (max-width: 912px) {
    width: 100%;
  }
`;
const CarouselContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem;

  @media screen and (max-width: 912px) {
    padding: 1rem;
  }
`;

export {
  Container,
  CardContainer,
  CardItem,
  Header,
  Company,
  CarouselContainer,
  Heading,
};
