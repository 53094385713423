import { Footer } from "components/common";
import {
  HeroSection,
  DolphySection,
  // SurvivorSection,
  UsedBySection,
  CustomerStories,
  RecognitionSection,
  SubscribeNowSection,
} from "components/Home";
import Chatbot from "components/Chatbot";
import { SubscribeProvider } from "context";

const Home = () => {
  return (
    <>
      <HeroSection />
      <DolphySection />
      {/* <SurvivorSection /> */}
      <UsedBySection />
      <CustomerStories />
      <RecognitionSection />
      <SubscribeProvider>
        <SubscribeNowSection />
      </SubscribeProvider>
      <Footer />
      <Chatbot />
    </>
  );
};

export default Home;
