import { Route, Switch } from "react-router";
import * as routes from "config/common/routes.config";
import {
  About,
  CoWorkSpace,
  DataPrivacy,
  Home,
  RefundCancellation,
  TermsConditions,
} from "pages";

const RootRoutes = () => {
  return (
    <Switch>
      <Route exact path={routes.home} component={Home}></Route>
      <Route exact path={routes.coWorkSpace} component={CoWorkSpace} />
      <Route exact path={routes.about} component={About} />
      <Route
        exact
        path={routes.refundCancellation}
        component={RefundCancellation}
      />
      <Route exact path={routes.termsConditions} component={TermsConditions} />
      <Route exact path={routes.dataPrivacy} component={DataPrivacy} />
      {/* <Route exact path={routes.survivr} component={Survivr} /> */}
    </Switch>
  );
};

export default RootRoutes;
