import {
  Container,
  InnerContainer,
  CompanyInfoContainer,
  Header,
  Description,
  SocialLinksContainer,
  CopyRightTextContainer,
} from "./Footer.styles";
import { Logo } from "components/common";
import * as routes from "config/common/routes.config";
import { ReactComponent as LogoIcon } from "assets/icons/brand-logo.svg";
import { ReactComponent as Facebook } from "assets/icons/Common/social-link-facebook.svg";
import { ReactComponent as Twitter } from "assets/icons/Common/social-link-twitter.svg";
import { ReactComponent as Linkedin } from "assets/icons/Common/social-link-linkedin.svg";
import { ReactComponent as F6s } from "assets/icons/Common/social-link-f6s.svg";
import { ReactComponent as Instagram } from "assets/icons/Common/social-link-instagram.svg";
import { ReactComponent as Youtube } from "assets/icons/Common/social-link-youtube.svg";

import LinksList from "./LinksList";
import { links } from "config/common";
import { useHistory } from "react-router-dom";

const pagesData = {
  header: "Pages",
  links: [
    {
      title: "Home",
      onClick: () => {
        window.location.href = links.footer.home;
      },
    },
    {
      title: "About Us",
      onClick: () => {
        window.location.href = links.navbar.dolphyAbout;
      },
    },
    // {
    //   title: "Blog",
    //   onClick: () => {
    //     window.location.href = links.navbar.blogs;
    //   },
    // },
    // {
    //   title: "Career",
    //   onClick: () => {
    //     window.location.href = links.footer.career;
    //   },
    // },
    {
      title: "Contact Us",
      onClick: () => {
        window.location.href = links.footer.doplhyContactUs;
      },
    },
  ],
};

const productData = {
  header: "Products",
  links: [
    {
      title: "Dolphy",
      onClick: () => {
        window.location.href = links.navbar.dolphy;
      },
    },
    {
      title: "Tweefeed",
      onClick: () => {
        window.location.href = links.navbar.tweefeed;
      },
    },
    {
      title: "ContentFeed",
      onClick: () => {
        window.location.href = links.navbar.contentfeed;
      },
    },

    // {
    //   title: "FrameGen",
    //   onClick: () => {
    //     window.location.href = links.footer.framegen;
    //   },
    // },
    // {
    //   title: "Survivr",
    //   onClick: () => {
    //     window.location.href = links.navbar.survivor;
    //   },
    // },
    // {
    //   title: "What we do",
    //   onClick: () => {
    //     window.location.href = links.footer.whatWeDo;
    //   },
    // },
  ],
};

const openSocialLinkHandler = (url: string) => {
  window.open(url, "_blank");
};

const Footer = () => {
  const history = useHistory();

  const companyData = {
    header: "Policies",
    links: [
      {
        title: "Refund & Cancellation",
        onClick: () => {
          history.push(routes.refundCancellation);
        },
      },
      {
        title: "Terms & Conditions",
        onClick: () => {
          history.push(routes.termsConditions);
        },
      },
      {
        title: "Data Privacy",
        onClick: () => {
          history.push(routes.dataPrivacy);
        },
      },
    ],
  };

  return (
    <Container>
      <InnerContainer>
        <CompanyInfoContainer>
          <Header>
            <Logo icon={LogoIcon} text="NULL Innovation" size="small" />
          </Header>
          <Description>
            NULL Innovation is a technology company offering IoT, AI enabled
            futuristic tech solutions in the areas of digital marketing and
            healthcare.
          </Description>
          <SocialLinksContainer>
            <Facebook
              onClick={() => openSocialLinkHandler(links.social.facebook)}
            />
            <Twitter
              onClick={() => openSocialLinkHandler(links.social.twitter)}
            />
            <Linkedin
              onClick={() => openSocialLinkHandler(links.social.linkedin)}
            />
            <Instagram
              onClick={() => openSocialLinkHandler(links.social.instagram)}
            />
            <Youtube
              onClick={() => openSocialLinkHandler(links.social.youtube)}
            />
            <F6s onClick={() => openSocialLinkHandler(links.social.f6s)} />
          </SocialLinksContainer>
        </CompanyInfoContainer>
        <LinksList header={pagesData.header} data={pagesData.links} />
        <LinksList header={productData.header} data={productData.links} />
        <LinksList header={companyData.header} data={companyData.links} />
      </InnerContainer>
      <CopyRightTextContainer>
        © NULL Innovation. All Rights Reserved.
      </CopyRightTextContainer>
    </Container>
  );
};

export default Footer;
