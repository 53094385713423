import { Footer, Navbar } from "components/common";
import { useEffect } from "react";
import styled from "styled-components";

const Container = styled.div`
  margin: 2rem auto;
  padding: 2rem;
  margin-bottom: 5rem;
  font-size: 16px;

  @media screen and (max-width: 912px) {
    padding: 1rem;
    margin: 1rem auto;
  }
`;

const Heading = styled.div`
  text-align: center;
  font-size: 25px;
  color: #3c6255;
  font-weight: bold;
  margin-bottom: 5rem;

  @media screen and (max-width: 912px) {
    font-size: 15px;
    margin-bottom: 4rem;
  }
`;

const Section = styled.section`
  background: #f8f8f8;
  padding: 2.5rem;
  font-size: 18px;
  border-radius: 8px;
  box-shadow: 0 0 4px 0 #555555;
  margin-bottom: 2rem;

  @media screen and (max-width: 912px) {
    padding: 1.5rem;
    font-size: 16px;
  }
`;

const List = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
  font-size: 16px;
  line-height: 2rem;

  @media screen and (max-width: 912px) {
    font-size: 16px;
  }
`;

const RefundCancellation = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <Container>
        <Heading>Refund & Cancellation Policy</Heading>

        <Section>
          <h2>Refund Policy</h2>
          <List>
            <li>
              If you wish to cancel your account, notify us at least 15 days
              before end of your term by going to profile section of the SaaS
              Service and apply “Cancel Subscription”. Upon cancellation, your
              data is deleted from our servers. Since deletion of all data is
              final, please be sure that you do in fact want to cancel your
              account before doing so.
            </li>
            <li>
              Your subscription gets renewed automatically at the end of each
              month.
            </li>
            <li>
              You can upgrade or downgrade level of our services at any time of
              your usage, however NULL Innovation does not refund any fees in
              that case.
            </li>
            <li>
              If you stop using our services in between the term, we will not
              refund you the fees paid by you for the remaining term.
            </li>
            <li>
              We reserve the right to modify or terminate the NULL Innovation
              service(s) for any reason, without notice at any time.
            </li>
            <li>
              <strong>Fraud:</strong> Without limiting any other remedies, NULL
              Innovation may suspend or terminate your account if we suspect
              that you (by conviction, settlement, insurance or investigation,
              or otherwise, forcefully removed our branding) have engaged in
              fraudulent activity in connection with the services offered by us.
            </li>
            <li>
              Note: No refunds are offered, even if a plan is canceled
              mid-month.
            </li>
          </List>
        </Section>

        <Section>
          <h2>15-Day No Obligation Cancellation</h2>
          <List>
            <li>
              NULL Innovation offers SaaS services to companies under a 15-day
              no obligation cancellation policy. The no obligation cancellation
              begins immediately upon account activation. The 15-day no
              obligation cancellation period only applies to new customers and
              does not apply to existing customers that have a subscription
              plan. NULL Innovation will also not honor a free trial period for
              customers who were once paying subscribers, cancelled, and then
              decided to re-instate their subscription to SaaS Services.
            </li>
            <li>
              At any time during the 15-day, potential subscriber (customer) can
              cancel their account and will be refunded in full with a minor
              service charge.
            </li>
            <li>
              In order to continue using SaaS after the 15-day no obligation
              cancellation period, you will be automatically be put to continue
              to your selected payment plan which you have selected initially at
              time of buying from range of selections of monthly or receiving a
              discount by paying for half yearly or full year of service.
            </li>
          </List>
        </Section>

        <Section>
          <h2>Monthly Billing, Cancellation & Refund Policy</h2>
          <List>
            <li>
              NULL Innovation charges and collects in advance for use of the
              SaaS service. All services rendered are non-refundable. Once a
              customer selects a subscription plan and provides billing
              information, NULL Innovation will calculate a pro-rated amount
              with the first payment due on signing.
            </li>
            <li>
              All subscriptions monthly, quarterly and yearly renew
              automatically on their due renewal date according to date of
              purchase until officially cancel the subscription from the profile
              section of the SaaS service. Customers will receive an email from
              NULL Innovation confirming that their subscription to the SaaS
              service has been cancelled.
            </li>
            <li>
              <strong>Important:</strong> No refunds or credits for partial
              months, quarters or years of service will be refunded to a
              customer upon cancellation. All fees are exclusive of all taxes,
              levies, or duties imposed by taxing authorities, and the customer
              is responsible for payments of all such taxes, levies, or duties.
            </li>
            <li>
              The fees that NULL Innovation charges for the monthly, quarterly,
              or yearly services exclude phone, and Internet access charges, as
              well as other data transmission charges. Any currency exchange
              settlements are based on the customer's agreement with the payment
              method provider. To be clear: these charges are the customer's
              responsibility.
            </li>
            <li>
              Customers have the ability to upgrade or downgrade their
              subscription plan for the SaaS services at any time. For any kind
              of upgrade or downgrade in subscription, customer has to go to
              profile section of SaaS service and upgrade and downgrade by
              clicking on respective button. The chosen method of billing will
              automatically be charged the new subscription rate on the next
              billing cycle.
            </li>
            <li>
              With regards to downgrades on a subscription, NULL Innovation does
              not issue refunds or credits for partial months of service.
            </li>
            <li>
              NULL Innovation reserves the right to refuse/cancel a subscription
              to any of the SaaS services bought from nullinnnovation.com, If
              NULL Innovation refuses a new or renewing subscription/membership,
              registrants will be offered a refund on prorata basis of pending
              days in subscription plan bought with an additional cancellation
              charge of $4.99.
            </li>
          </List>
        </Section>
        <p>
          These above policies apply to all the SaaS services listed on NULL
          Innovation unless otherwise noted in the corresponding program
          materials.
        </p>
      </Container>
      <Footer />
    </>
  );
};

export default RefundCancellation;
