import { Helmet } from "react-helmet";
import { Footer } from "components/common";
import {
  BenefitSection,
  ContactSection,
  Header,
  HeroSection,
  PlanSection,
  PropositionSection,
} from "components/CoWorkSpace";

const CoWorkSpace = () => {
  return (
    <main>
      <Helmet>
        <title>NULL Co-Space for Founders and Freelancers</title>
      </Helmet>
      <Header />
      <HeroSection />
      <PropositionSection />
      <BenefitSection />
      <PlanSection />
      <ContactSection />
      <Footer />
    </main>
  );
};

export default CoWorkSpace;
