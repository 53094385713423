import styled from "styled-components";

const CardContainer = styled.div`
  padding: 12px;
  box-shadow: 0 0 4px 0 #555555;
  text-align: center;
  margin-bottom: 2rem;

  @media screen and (max-width: 789px) {
    padding: 10px;
    margin-bottom: 1rem;
    flex-direction: column;
  }
`;

const MemberPicture = styled.img`
  width: 100%;
  height: 100%;
`;

const MemberName = styled.p`
  font-size: 20px;
  font-weight: 500;

  @media screen and (max-width: 789px) {
    font-size: 15px;
  }
`;

const MemberRole = styled.p`
  font-size: 14px;
  @media screen and (max-width: 789px) {
    font-size: 10px;
  }
`;

const LinkedinIcon = styled.img`
  width: 32px;
  height: 32px;
`;

const IconButton = styled.button`
  padding: unset;
  border: unset;

  @media screen and (max-width: 789px) {
    padding: 10px;
  }
`;

export {
  CardContainer,
  MemberPicture,
  MemberName,
  MemberRole,
  LinkedinIcon,
  IconButton,
};
