import { Footer, Navbar } from "components/common";
import { useEffect } from "react";
import styled from "styled-components";

const Container = styled.div`
  margin: 2rem auto;
  padding: 2rem;
  margin-bottom: 5rem;
  font-size: 14px;

  @media screen and (max-width: 912px) {
    padding: 1rem;
    margin: 1rem auto;
  }
`;

const Heading = styled.div`
  text-align: center;
  font-size: 25px;
  color: #3c6255;
  font-weight: bold;
  margin-bottom: 5rem;

  @media screen and (max-width: 912px) {
    font-size: 15px;
    margin-bottom: 4rem;
  }
`;

const Section = styled.section`
  background: #f8f8f8;
  padding: 2.5rem;
  font-size: 18px;
  border-radius: 8px;
  box-shadow: 0 0 4px 0 #555555;
  margin-bottom: 2rem;

  @media screen and (max-width: 912px) {
    padding: 1.5rem;
    font-size: 16px;
  }
`;

const List = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
  font-size: 16px;
  line-height: 2rem;

  @media screen and (max-width: 912px) {
    font-size: 16px;
  }
`;

const DataPrivacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <Container>
        <Heading>Privacy Policy</Heading>

        <Section>
          <h2>Privacy Policy</h2>
          <List>
            <li>
              We at NULL Innovation Inc ("NULL") respect your privacy and the
              confidentiality of your personal information.
            </li>
            <li>
              This Privacy Policy explains what information NULL collects, and
              how we use and disclose that information.
            </li>
            <li>
              This Privacy Statement applies to all websites, services, and
              applications that link to it ("Services").
            </li>
            <li>
              We may change this Privacy Policy from time to time. If we make
              changes, we will notify you by revising the date at the top of the
              policy and, in some cases, we may provide you with additional
              notice (such as sending you an email notification).We encourage
              you to review the Privacy Policy whenever you access the Services
              or otherwise interact with us to stay informed about our
              information practices.
            </li>
            <li>
              This privacy policy has been compiled to better serve those who
              are concerned with how their 'Personally Identifiable Information'
              (PII) is being used online. PII, as described in US privacy law
              and information security, is information that can be used on its
              own or with other information to identify, contact, or locate a
              single person, or to identify an individual in context. Please
              read our privacy policy carefully to get a clear understanding of
              how we collect, use, protect or otherwise handle your Personally
              Identifiable Information in accordance with our website.
            </li>
          </List>
        </Section>

        <Section>
          <h2>Information Collected (Information you Provide)</h2>
          <p>
            We collect personal information that you provide when you visit our
            website, blog, social media site or app:
          </p>
          <List>
            <li>
              In order to purchase, use or download our NULL FrameGen (IoT)
              software or web service, you are required to complete the
              registration from that includes first name, last name, email
              address, company name and phone number as well as answer to
              security questions.
            </li>
            <li>
              If you decide to use any of our services including FrameGen
              Product development decision framework, we further ask you
              specific information about your product or idea including product
              specifications,budget, product brand,potential feature of the
              product/idea you are developing in order to generate a decision
              framework or other details to help you with your experience with
              our product and services. We refer to this information as
              “Product/Idea Information”.
            </li>
            <li>
              If you decide to purchase our products, we may request additional
              information, including payment information.
            </li>
            <li>
              If you pay using a credit card, we will collect financial
              information such as credit card number, and expiration date.
            </li>
            <li>
              When you use our technical support and customer service functions
              or otherwise contact our support staff, we collect information
              about the questions or concerns you submit.
            </li>
            <li>
              If you choose to use our referral service to tell a friend about
              our site, we will ask you for your friend's name and email
              address.
            </li>
          </List>
        </Section>

        <Section>
          <h2>Automatically Collected Information</h2>
          <List>
            <li>
              We collect information from you when you register on our site,
              place an order, subscribe to a newsletter, fill out a form, use
              live chat, enter information on our site or provide us with
              feedback on our products or services
            </li>
            <li>
              All subscriptions monthly, quarterly and yearly renew
              automatically on their due renewal date according to date of
              purchase until officially cancel the subscription from the profile
              section of the SaaS service. Customers will receive an email from
              NULL Innovation confirming that their subscription to the SaaS
              service has been cancelled.
            </li>
            <li>
              Additionally, our servers automatically record your computer’s
              Internet Protocol (‘IP”) address, browser type or the webpage you
              were visiting before you came to our Site, the pages of our Site
              that you visit, the time you spent on those pages, information
              search on our Site, access date and time, country and other
              statistics. We use this information to monitor and analyze the use
              of our Site, and the Service and the Site’s technical
              administration, to increase Site’s functionality and user
              friendliness, and to better tailor our visitors’ needs. We refer
              to it as “Site Data” and we do not treat Site Data as a personal
              information or use it in association with other Personal
              Information.
            </li>
          </List>
        </Section>

        <Section>
          <h2>Use of Your Information</h2>
          <List>
            <li>
              We use and disclosure of the information we received through our
              web services including the product development information,
              product technical specifications and features, only to provide the
              particular user a relevant output. We don’t share the user’s
              product information with any other user or third party website for
              commercial use. This does not include website hosting partners and
              other parties who assist us in operating our website, conducting
              our business, or serving our users, so long as those parties agree
              to keep this information confidential. We take confidentiality of
              user’s product information at the highest level.
            </li>
            <li>
              We may use the information we collect from you when you register,
              make a purchase, sign up for our newsletter, respond to a survey
              or marketing communication, surf the website, or use certain other
              Site features in the following ways:
              <List>
                <li>
                  To personalize your experience and to allow us to deliver the
                  type of content and product offerings in which you are most
                  interested.
                </li>
                <li>
                  To allow us to better service you in responding to your
                  customer service requests.
                </li>
                <li>To quickly process your transactions.</li>
              </List>
            </li>
          </List>
        </Section>

        <Section>
          <h2>Protecting your information</h2>
          <List>
            <li>
              Our website is scanned on a regular basis for security holes and
              known vulnerabilities in order to make your visit to our site as
              safe as possible.We use regular Malware Scanning.
            </li>
            <li>
              Your personal information is contained behind secured networks and
              is only accessible by a limited number of persons who have special
              access rights to such systems, and are required to keep the
              information confidential. In addition, all sensitive/credit
              information you supply is encrypted via Secure Socket Layer (SSL)
              technology.
            </li>
            <li>
              We implement a variety of security measures when a user places an
              order enters, submits, or accesses their information to maintain
              the safety of your personal information.
            </li>
            <li>
              All transactions are processed through a gateway provider and are
              not stored or processed on our servers.
            </li>
          </List>
        </Section>

        <Section>
          <h2>Cookies</h2>
          <List>
            <li>
              Yes. Cookies are small files that a site or its service provider
              transfers to your computer's hard drive through your Web browser
              (if you allow) that enables the site's or service provider's
              systems to recognize your browser and capture and remember certain
              information. For instance, we use cookies to help us remember and
              process the information in your IoT Central account, and shopping
              cart. They are also used to help us understand your preferences
              based on previous or current site activity, which enables us to
              provide you with improved services. We also use cookies to help us
              compile aggregate data about site traffic and site interaction so
              that we can offer better site experiences and tools in the future.
            </li>
            <li>
              We use cookies to:
              <List>
                <li>
                  Help remember and process the information in the IoT Central
                  account and items in shopping cart.
                </li>
                <li>
                  Understand and save user's preferences for future visits.
                </li>
                <li>Keep track of advertisements.</li>
                <li>
                  Compile aggregate data about site traffic and site
                  interactions in order to offer better site experiences and
                  tools in the future. We may also use trusted third-party
                  services that track this information on our behalf.
                </li>
              </List>
            </li>
            <li>
              You can choose to have your computer warn you each time a cookie
              is being sent, or you can choose to turn off all cookies. You do
              this through your browser settings. Since browser is a little
              different, look at your browser's Help Menu to learn the correct
              way to modify your cookies.
            </li>
            <li>
              If users disable cookies in their browser:
              <List>
                <li>
                  If you turn cookies off, Some of the features that make your
                  site experience more efficient may not function properly.Some
                  of the features that make your site experience more efficient
                  and may not function properly.
                </li>
                <li>
                  <strong>DO NOT TRACK (DNT) SIGNALS: </strong>
                  We honor Do Not Track signals and Do Not Track, plant cookies,
                  or use advertising when a Do Not Track (DNT) browser mechanism
                  is in place.
                </li>
              </List>
            </li>
          </List>
        </Section>

        <Section>
          <h2>Third-party disclosure</h2>
          <List>
            <li>
              We do not sell, trade, or otherwise transfer to outside parties
              your Personally Identifiable Information unless we provide users
              with advance notice. This does not include website hosting
              partners and other parties who assist us in operating our website,
              conducting our business, or serving our users, so long as those
              parties agree to keep this information confidential. We may also
              release information when it's release is appropriate to comply
              with the law, enforce our site policies, or protect ours or
              others' rights, property or safety.
            </li>
            <li>
              However, non-personally identifiable visitor information may be
              provided to other parties for marketing, advertising, or other
              uses.
            </li>
            <li>
              <strong>THIRD-PARTY LINKS</strong>
              <List>
                <li>
                  Occasionally, at our discretion, we may include or offer
                  third-party products or services on our website. These
                  third-party sites have separate and independent privacy
                  policies. We therefore have no responsibility or liability for
                  the content and activities of these linked sites. Nonetheless,
                  we seek to protect the integrity of our site and welcome any
                  feedback about these sites.
                </li>
              </List>
            </li>
            <li>
              <strong>GOOGLE</strong>
              <List>
                <li>
                  Google's advertising requirements can be summed up by Google's
                  Advertising Principles. They are put in place to provide a
                  positive experience for users We have not enabled Google
                  AdSense on our site but we may do so in the future.
                </li>
              </List>
            </li>
          </List>
        </Section>

        <Section>
          <h2>California Online Privacy Protection Act</h2>
          <List>
            <li>
              CalOPPA is the first state law in the nation to require commercial
              websites and online services to post a privacy policy. The law's
              reach stretches well beyond California to require any person or
              company in the United States (and conceivably the world) that
              operates websites collecting Personally Identifiable Information
              from California consumers to post a conspicuous privacy policy on
              its website stating exactly the information being collected and
              those individuals or companies with whom it is being shared. - See
              more at:{" "}
              <a
                href="http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf"
                target="_blank"
                rel="noreferrer"
              >
                http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf
              </a>
            </li>
            <li>
              <strong>ACCORDING TO CALOPPA, WE AGREE TO THE FOLLOWING:</strong>
              <List>
                <li>
                  Users can visit our site anonymously. Once this privacy policy
                  is created, we will add a link to it on our home page or as a
                  minimum, on the first significant page after entering our
                  website.Our Privacy Policy link includes the word 'Privacy'
                  and can easily be found on the page specified above.
                </li>
                <li>
                  You will be notified of any Privacy Policy changes: On our
                  Privacy Policy Page
                </li>
                <li>
                  Can change your personal information: By logging in to your
                  account
                </li>
              </List>
            </li>
            <li>
              <strong>THIRD-PARTY BEHAVIORAL TRACKING</strong>
              <List>
                <li>
                  It's also important to note that we allow third-party
                  behavioral tracking.
                </li>
              </List>
            </li>
          </List>
        </Section>

        <Section>
          <h2>COPPA (Children's Online Privacy Protection Act) </h2>
          <List>
            <li>
              When it comes to the collection of personal information from
              children under the age of 13 years old, the Children's Online
              Privacy Protection Act (COPPA) puts parents in control. The
              Federal Trade Commission, United States' consumer protection
              agency, enforces the COPPA Rule, which spells out what operators
              of websites and online services must do to protect children's
              privacy and safety online. We do not specifically market to
              children under the age of 13 years old.
            </li>
          </List>
        </Section>

        <Section>
          <h2>Fair Information Practices</h2>
          <List>
            <li>
              The Fair Information Practices Principles form the backbone of
              privacy law in the United States and the concepts they include
              have played a significant role in the development of data
              protection laws around the globe. Understanding the Fair
              Information Practice Principles and how they should be implemented
              is critical to comply with the various privacy laws that protect
              personal information.
            </li>
            <li>
              <strong>
                IN ORDER TO BE IN LINE WITH FAIR INFORMATION PRACTICES WE WILL
                TAKE THE FOLLOWING RESPONSIVE ACTION, SHOULD A DATA BREACH
                OCCUR:
              </strong>
              <List>
                <li>We will notify you via email: Within 7 business days</li>
                <li>
                  We will notify the users via in-site notification: Within 7
                  business days
                </li>
              </List>
            </li>
            <li>
              We also agree to the Individual Redress Principle which requires
              that individuals have the right to legally pursue enforceable
              rights against data collectors and processors who fail to adhere
              to the law. This principle requires not only that individuals have
              enforceable rights against data users, but also that individuals
              have recourse to courts or government agencies to investigate
              and/or prosecute non-compliance by data processors.
            </li>
          </List>
        </Section>
        <p>
          <strong>
            IF YOU FEEL THAT WE ARE NOT ABIDING BY THIS PRIVACY POLICY, YOU
            SHOULD CONTACT US IMMEDIATELY VIA TELEPHONE AT 619-430-6462 OR VIA{" "}
            <a href="mailto:MARKETING@NULLIOT.COM">MARKETING@NULLIOT.COM.</a>
          </strong>
        </p>
      </Container>
      <Footer />
    </>
  );
};

export default DataPrivacy;
